import { Box, Heading, Logo, Stack, Text } from '@vinomofo/components';
import styled from 'styled-components';
import { getParameterFromURLSearch } from '../../utils/get-parameter-from-url';

const StyledUL = styled.ul`
  margin: 4px;
  li {
    margin-bottom: 4px;
    p {
      line-height: 1.2;
    }
  }
`;

const Render = () => {
  const redirect_uri = getParameterFromURLSearch('redirect_uri', window.location.search);
  const isSG = redirect_uri?.includes('vinomofo.com.sg');
  return (
    <Box display="flex" justifyContent="center">
      <Box maxWidth={['380px', '480px']} p={[2, 3, 4]} zIndex="1">
        <Stack space={1}>
          <Stack space={2}>
            <Box width={150} alignSelf={'center'}>
              <Logo vinoColor="white" />
            </Box>
            <Heading marginTop={21} fontSize={[32, 38, 48]} textAlign="center" color="white" lineHeight={'0.2'}>
              DO YOU
              <Text lineHeight={1} fontSize={[64, 78, 96]} textAlign="center" color="white">
                MOFO?
              </Text>
            </Heading>
          </Stack>

          <Text fontSize={22} lineHeight={1.2} textAlign="center" color="white" fontWeight="500">
            If you’re about good wine only, <br /> epic value and happiness, <br />
            guaranteed - you’re one of us.
          </Text>

          <Box display="flex" justifyContent="center" fontWeight="500" mt="10px">
            <Stack>
              <Text fontWeight="bold" color="white" fontSize={[16, 18]} marginLeft={3}>
                JOIN TODAY TO ENJOY:
              </Text>
              <Box color="white" display="flex" alignItems="center" fontSize={[16, 18]}>
                <StyledUL>
                  <li>
                    <Text color="white" fontSize="inherit" lineHeight={1.4}>
                      Get first dibs on epic wine deals
                    </Text>
                  </li>
                  <li>
                    <Text color="white" fontSize="inherit" lineHeight={1.4}>
                      Personalised recommendations, tailored to your taste
                    </Text>
                  </li>
                  <li>
                    <Text color="white" fontSize="inherit" lineHeight={1.4}>
                      Free returns & free shipping on {isSG ? `$200+` : '3+ cases*'}
                    </Text>
                  </li>
                </StyledUL>
              </Box>
            </Stack>
          </Box>
          {!isSG && (
            <Box mt={[1, 3, 6]} mb={[4, 0, 0]}>
              <Text color={'white'} fontSize={'1'}>
                *Excludes NT, sorry top end mates.
              </Text>
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default Render;
