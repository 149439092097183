import { Link as RouteLink } from 'react-router-dom';
import styled from 'styled-components';

import { Text } from '@vinomofo/components';
import { getHashParams } from '../../utils/hash-params';

const Link = styled(RouteLink)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Render = () => {
  const hashParams = getHashParams(window.location.hash);
  const returnTo = hashParams.returnTo || 'https://vinomofo.com';
  return (
    <div>
      <Text fontSize="12px" color="white">
        Already a Mofo? <Link to={`/auth/login/using-password?returnTo=${returnTo}`}>Click here</Link> to login.
      </Text>
    </div>
  );
};

export default Render;
