import { ReactNode } from 'react';
import Helmet from 'react-helmet';
import { Route, HashRouter as Router, Switch, Link as RouteLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Button, Card, Logo, Section, Stack, Text } from '@vinomofo/components';
import { ArrowLeftIcon } from '@vinomofo/icons';

import useApplicationState from '../../hooks/use-application-state';
import Onboard from '../onboard';
import Welcome from '../welcome';
import { getHashParams } from '../../utils/hash-params';
import { getParameterFromURLSearch } from '../../utils/get-parameter-from-url';

export const HyperLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

const FONT_URL = '//fonts.vinomofo.io/css?family=GT+Walsheim:500,700&family=Graphik:400,400italic,500,500italic';
const bgImage = 'https://res.cloudinary.com/vinomofo/image/upload/q_auto/v1713746713/assets/vinomofo-identity-bg.png';

const Container = styled(Box)`
  position: relative;
  background-color: #000;
  background-repeat: no-repeat;
  background-image: url(${bgImage});
  background-position: 50% 0;
  background-size: cover;
  @media ${({ theme }) => theme.mq.max.mobile} {
    background-position: 63% 0;
  }
`;

const CardHeader = styled(Box)`
  text-align: center;

  svg {
    width: auto;
    height: 42px;
    vertical-align: middle;
  }
`;

export const ErrorBox = styled(Box)`
    margin: 0 auto;
    width: 100%;
    padding: 20px;
    text-align: center;
    border: 1px solid #cf2c30;
    background-color: #cf2c30;
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    font-family: "GT Walsheim",sans-serif;
    overflow-wrap: break-word;
    white-space: normal;

      a {
        text-align: center;
        display: inline;
      }
    }
    `;

const StyledBackButton = styled(Button)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  svg {
    height: 30px;
  }
`;

const StyledTextReturnToSite = styled(Text)`
  @media ${({ theme }) => theme.mq.max.mobile} {
    position: absolute;
    left: 0;
    top: 10px;
  }
`;

const Layout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const history = useHistory();
  const { error, formData } = useApplicationState();
  const redirect_uri = getParameterFromURLSearch('redirect_uri', window.location.search);
  const segmentKey = redirect_uri?.includes('vinomofo.com.sg')
    ? process.env.REACT_APP_SEGMENT_API_KEY_SG
    : process.env.REACT_APP_SEGMENT_API_KEY_AU;

  const origin = redirect_uri?.split('?')[0];
  const hashParams = getHashParams(window.location.hash);
  let returnToParam = hashParams.returnTo;

  const returnOrigin =
    returnToParam && returnToParam?.includes('vinomofo.com') ? '' : origin?.slice(0, origin?.lastIndexOf('/'));
  if (returnToParam?.includes('/wine-clubs/') && returnToParam?.includes('/mofo-club'))
    returnToParam = '/wine-clubs/mofo-club';
  if (returnToParam?.includes('/wine-clubs/') && returnToParam?.includes('/black-market-club'))
    returnToParam = '/wine-clubs/black-market-club';
  const defaultOrigin = redirect_uri?.includes('vinomofo.com.sg')
    ? 'https://www.vinomofo.com.sg'
    : 'https://www.vinomofo.com';
  const returnTo = returnToParam ? `${returnOrigin}${returnToParam}` : defaultOrigin;
  const showContinueAsGuest =
    returnToParam && (returnToParam.indexOf('/cart') > -1 || returnToParam.indexOf('/guest-checkout') > -1);

  const showBackButton =
    window.location.hash.includes('/auth/login/using-password') || window.location.hash.includes('/auth/join');

  const goBack = () => {
    if (location.pathname.includes('/auth/login/forgot-password/')) {
      history.push('/auth/login/using-password');
    } else {
      history.goBack();
    }
  };

  return (
    <Container minHeight="100vh">
      <Helmet>
        <link rel="stylesheet" href={FONT_URL} />
        <script>
          {segmentKey &&
            `
            !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.15.2";
            }}();
            analytics.load('${segmentKey}');
            `}
        </script>
      </Helmet>
      <Section>
        <Box
          minHeight="100vh"
          display={['block', 'block', 'flex']}
          justifyContent="center"
          alignItems="flex-start"
          pt={[4, 4, 7]}
          pb={[4]}
        >
          <Router>
            <Box flex="1">
              <Switch>
                <Route exact path="/auth/login" component={Welcome} />
                <Route exact path="/auth/login/using-password" component={Welcome} />
                <Route exact path="/auth/login/verify/:email" component={Welcome} />
                <Route exact path="/auth/login/forgot-password" component={Welcome} />
                <Route exact path="/auth/resend/:email" component={Welcome} />
                <Route exact path="/auth/join" component={Onboard} />
                <Route exact path="/auth/join/verify/:email" component={Onboard} />
              </Switch>
            </Box>
            <Box flex="1">
              <Card backgroundColor="rgba(0, 0, 0, 0.75)" display="block" px={[4, 80]} pt={[30, 2]} pb={5}>
                <Box pt={4} pb={1} px={[4, 0]} display={['block']}>
                  <StyledTextReturnToSite
                    fontSize={['32px', '14px', '14px']}
                    textAlign={'left'}
                    px={[4, 0]}
                    pt={4}
                    pb={1}
                  >
                    {showBackButton ? (
                      <HyperLink
                        href={
                          showContinueAsGuest && formData?.email ? `${returnTo}?email=${formData.email}` : `${returnTo}`
                        }
                      >
                        <ArrowLeftIcon />{' '}
                        <Box display={['none', 'block']} style={{ marginLeft: 5 }}>
                          Return to site
                        </Box>
                      </HyperLink>
                    ) : (
                      <StyledBackButton onClick={goBack}>
                        <ArrowLeftIcon height="20" />
                      </StyledBackButton>
                    )}
                  </StyledTextReturnToSite>
                </Box>
                <Stack space={3}>
                  {/* {error && (
                    <ErrorBox>
                      {showContinueAsGuest ? (
                        <>
                          {error} <Link to={`/auth/login?returnTo=${returnTo}`}>Click here</Link> to login with just
                          your email and we&apos;ll send you a 6 digit code or <br />
                          <HyperLink
                            href={
                              showContinueAsGuest && formData?.email
                                ? `${returnTo}?email=${formData.email}`
                                : `${returnTo}`
                            }
                          >
                            continue checkout as guest.
                          </HyperLink>
                        </>
                      ) : (
                        <>
                          {error} <Link to={`/auth/login?returnTo=${returnTo}`}>Click here</Link> to login with just
                          your email and we&apos;ll send you a 6 digit code.
                        </>
                      )}
                    </ErrorBox>
                  )} */}
                  {children}
                </Stack>
              </Card>
            </Box>
          </Router>
        </Box>
      </Section>
    </Container>
  );
};

export default Layout;
